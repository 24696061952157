import React from 'react';
import style from './style.css';
import Tabs from '../Tabs';
import { ResourceCenter } from 'cccisd-laravel-resources';

const user = window.cccisd.fortress.user.acting;
const isUber = user.data_type === 'uberadmin';
const licenses = {
    dart: !isUber ? user.group.type_data.dartLicense : 1,
    wfi: !isUber ? user.group.type_data.wfiLicense : 1,
    tom: !isUber ? user.group.type_data.tomLicense : 1,
};
const toolPermissions = !isUber ? JSON.parse(user.role_data.toolPermissions) : [];

const urlParams = new URLSearchParams(window.location.search);
let urlTabName = encodeURIComponent(urlParams.get('tab'));
const urlPath = location.href.split('/');
// if coming from a share link i.e. /resourceCenter/GeneralResources/21
if (urlPath.length > 4) {
    urlTabName = location.href.split('/')[4];
}

export default class ResourceCenterTab extends React.Component {
    state = {
        tabs: [],
    };

    componentDidMount = () => {
        this.getTabs();
    };

    onTabChange = (activeTabName, initTab) => {
        if (initTab) history.replaceState(null, '', `${location.href.split('?')[0]}?tab=${activeTabName}`);
        else history.replaceState(null, '', `${location.href.split('/').slice(0, 4).join('/')}/?tab=${activeTabName}`);
    };

    getTabs = () => {
        const { match } = this.props;

        const tabs = [
            {
                name: 'WFI-EZ%20Resource%20Center',
                title: 'WFI-EZ',
                handle: 'wfi',
                content: <ResourceCenter handle="WFI-EZ" match={match} />,
            },
            {
                name: 'TOM%202.0%20Resource%20Center',
                title: 'TOM 2.0',
                handle: 'tom',
                content: <ResourceCenter handle="tom2.0" match={match} />,
            },
            {
                name: 'DART%20Resource%20Center',
                title: 'DART',
                handle: 'dart',
                content: <ResourceCenter handle="dart" match={match} />,
            },
        ].filter(tab => {
            if (!isUber) {
                // if the group is allowed
                if (licenses[tab.handle]) {
                    // if the user has permissions set
                    if (toolPermissions && toolPermissions.length > 0) {
                        return toolPermissions.includes(tab.handle);
                    }
                    return true;
                }
            } else {
                // if uberadmin
                return true;
            }
            return false;
        });

        tabs.unshift({
            name: 'General%20Resources',
            title: 'General',
            handle: 'general',
            content: <ResourceCenter handle="General" match={match} />,
        });
        this.setState({ tabs });
    };

    render() {
        return (
            <div className={style.pageMargin}>
                <h2>Resource Center</h2>
                {this.state.tabs.length > 0 ? (
                    <Tabs
                        onClick={this.onTabChange}
                        tabList={this.state.tabs}
                        tabId="wrapStatTools"
                        initTabName={urlTabName}
                    />
                ) : (
                    <div>
                        <h4>Permission Denied</h4>
                        <h5>You have no tool access.</h5>
                    </div>
                )}
            </div>
        );
    }
}
